import { Component, Input } from '@angular/core';
import { AuthenticationService, RedirectService } from '@wilson/wilsonng';
import { InitialLoadService } from '../../../services/initial-load.service';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'hub-subscribe',
  templateUrl: './hub-subscribe.component.html',
  styleUrls: ['./hub-subscribe.component.scss'],
})
export class HubSubscribeComponent {
  @Input() userLoggedIn = false;

  baseFhpUrl: string;
  currentYear: number = new Date().getFullYear();

  constructor(
    private authenticationService: AuthenticationService,
    private redirectService: RedirectService,
    private initialLoadService: InitialLoadService
  ) {
    this.baseFhpUrl = this.initialLoadService.initialLoad.paths['FHP'];
  }

  loginStudent(): void {
    window.location.href = `${this.baseFhpUrl}login`;
  }

  loginTeacher(): void {
    if (this.userLoggedIn) {
      this.redirectService.setRedirectCookie('/funhub');
      this.authenticationService.logout();
      mixpanel.track('Trigger Re-Login from Hub Landing');
    } else {
      this.authenticationService.login('/funhub');
    }
  }
}
