<div class="landing">
  <div class="content">
    <div>
      <img
        class="wlt-logo"
        [routerLink]="['/']"
        title="Navigate to Home"
        src="/assets/img/wlt-stacked.svg"
        alt="Wilson Language Training logo"
      />
      <div class="fun-wrapper">
        <img
          class="w-100"
          src="/assets/img/fundations.svg"
          alt="Fundations logo"
        />
      </div>
    </div>

    <div class="text-center">
      <h1>FUN HUB<sup>&reg;</sup> + Fundations<sup>&reg;</sup></h1>
      <h2 class="fw-bold">Are Better Together</h2>

      <div class="actions mt-4">
        <button
          class="wlt-btn btn-primary student-login"
          (click)="loginStudent()"
        >
          Student Login
        </button>
        <button
          class="wlt-btn btn-primary teacher-login"
          (click)="loginTeacher()"
        >
          Teacher + Admin Login
        </button>
      </div>
    </div>
  </div>

  <footer>
    <p>
      &copy; {{ currentYear }} Wilson Language Training Corporation. All Rights
      Reserved.
    </p>
  </footer>
</div>
