<div class="funnel-container hub">
  <wilson-skip-to *ngIf="!isPublic" skipToId="mainContent"> </wilson-skip-to>
  <app-header *ngIf="!isPublic"></app-header>

  <div class="container" id="mainContent">
    <div class="card message" *ngIf="message">
      <img src="/assets/img/fun-owl.svg" alt="Fundations owl" />
      <p class="mb-0" [innerHtml]="message"></p>
    </div>

    <div class="card p-5">
      <h3
        class="text-center fw-bold mb-5"
        *ngIf="header"
        [innerHtml]="header"
      ></h3>

      <div class="hub-tiles row">
        <wilson-link-tile
          *ngFor="let level of hubLevels; trackBy: getHubLevelId"
          class="col-sm-12 col-md-6 col-lg-3"
          [imageSrc]="level.imageUrl"
          [heading]="level.name"
          [description]="level.description"
          linkText="Open External Link &raquo;"
          (tileClicked)="goToHub(level.url); trackTileClick(level.name)"
        >
        </wilson-link-tile>
      </div>
    </div>
  </div>

  <app-footer *ngIf="!isPublic"></app-footer>
</div>
