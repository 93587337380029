import { Component, OnInit } from '@angular/core';
import { Wilson } from 'src/def/Wilson';
import ResourceType = Wilson.ResourceType;
import CollectionResource = Wilson.CollectionResource;
import { AdminService } from 'src/app/services/admin.service';
import { tap } from 'rxjs/operators';
import ExternalLinkResource = Wilson.ExternalLinkResource;
import { ActivatedRoute, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { AuthenticationService } from '@wilson/wilsonng';
import { UserService } from '../../../services/user.service';
import ProgramType = Wilson.ProgramType;
import { FunnelService } from '../../../services/funnel.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'hub-collection',
  templateUrl: './hub-collection.component.html',
  styleUrls: ['./hub-collection.component.scss'],
})
export class HubCollectionComponent implements OnInit {
  message: string;
  header: string;
  hubLevels: ExternalLinkResource[];
  isPublic = false;

  private collection: string = 'hub-landing';

  constructor(
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private funnelService: FunnelService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  goToHub(hubUrl: string) {
    if (this.authenticationService.isLoggedIn()) {
      void this.router.navigate([hubUrl]);
    } else {
      this.authenticationService.login(hubUrl);
    }
  }

  trackTileClick(levelName: string): void {
    mixpanel.track('Clicked Hub Tile', {
      'Go To': levelName,
    });
  }

  private init(): void {
    this.isPublic = this.route.snapshot.data['isPublic'];

    const lastProgramHub =
      this.funnelService.getFunnelConfiguration(
        this.userService.user?.lastViewedProgramId
      )?.subType === ProgramType.hub;

    if (
      this.isPublic &&
      this.authenticationService.isLoggedIn() &&
      lastProgramHub
    ) {
      // just redirect the user to their last hub program
      void this.router.navigate([
        '/',
        this.userService.user.lastViewedProgramId,
      ]);
      return;
    }
    this.getHubLandingResource().subscribe();
  }

  private getHubLandingResource(): Observable<Wilson.BaseResource> {
    return this.adminService.getHubLandingResource().pipe(
      tap((landing: CollectionResource) => {
        const banner = landing.childResources.find(
          (resource) => resource.id === `${this.collection}-owl-banner`
        );
        const header = landing.childResources.find(
          (resource) => resource.id === `${this.collection}-welcome-banner`
        );

        const childResources = landing.childResources as ExternalLinkResource[];

        if (banner.isPublished) this.message = banner.description;
        if (header.isPublished) this.header = header.description;
        this.hubLevels = childResources.filter(
          (resource) =>
            resource.type !== ResourceType.text && resource.isPublished
        );
      })
    );
  }

  getHubLevelId = (index: number, level: ExternalLinkResource) => level.id;
}
