import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService, TitleService } from '@wilson/wilsonng';
import { Wilson } from 'src/def/Wilson';
import Roles = Wilson.Roles;
import ProgramType = Wilson.ProgramType;
import { FunnelService } from 'src/app/services/funnel.service';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';

@Component({
  templateUrl: './hub-landing.component.html',
})
export class HubLandingComponent implements OnInit {
  showHubChooser = false;
  showSignUpPage = false;
  userLoggedIn = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private funnelService: FunnelService,
    private router: Router,
    private titleService: TitleService
  ) {
    mixpanel.track('Arrived on Hub Landing');
    this.userLoggedIn = this.authenticationService.isLoggedIn();
    const userHasHubRole = this.authenticationService.userHasRole(
      Roles.subscription_Fundations_AllLevels
    );
    const lastProgramHub =
      this.funnelService.getFunnelConfiguration(
        this.userService.user?.lastViewedProgramId
      )?.subType === ProgramType.hub;

    if (this.userLoggedIn && userHasHubRole) {
      if (lastProgramHub) {
        // just redirect the user to their last hub program
        void this.router.navigate([
          '/',
          this.userService.user.lastViewedProgramId,
        ]);
      } else {
        // logged in and a hub user, but did not last view a hub program, show them the "hub chooser"
        this.showHubChooser = true;
        mixpanel.track('Directed to Hub Chooser');
      }
    } else {
      // not a hub user, or not logged in
      // // let's give them the option to subscribe (or log in)
      this.showSignUpPage = true;
      mixpanel.track('Directed to Hub Sign Up/Login');
    }
  }

  ngOnInit(): void {
    this.titleService.setPageName('');
    this.titleService.setProductOverride('FUN HUB');
  }
}
